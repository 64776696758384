<template>
    <div>
        <b-card class="reports-list">
            <b-card-header class="card-header-main">
                <b-row class="reports-list-header">
                    <b-col md="2">
                        <b-button
                            @click="createModalIsActive = !createModalIsActive"
                            class="text-nowrap"
                            variant="primary"
                        >
                            <i class="icon-plus mr-1"></i>Create Category
                        </b-button>
                    </b-col>
                    <b-col md="2">
                        <project-router-link to="/customqueries">
                            <b-button class="text-nowrap" variant="primary">
                                <i class="icon-plus mr-1"></i>Custom Queries
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :fields="customQueriesColumns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="customQueriesTable"
                    :default-per-page="10"
                >
                    <template v-slot:cell(lastModified)="{item}">{{ parseLocalDate(item.updatedAt) }}</template>
                    <template v-slot:cell(dedicated)="{item}">
                        <div class="query-dedicated-row">
                            <TrueOrFalseIcon :value="item.access.some(a => a.id === 'All')" />
                        </div>
                    </template>

                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <DetailsAction @click="openUpdateModal(item)" />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <b-modal
            v-model="createModalIsActive"
            size="lg"
            centered
            title="Create new category"
            @show="resetCreateModal"
            @hidden="resetCreateModal"
            @ok="createCategory"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Category" label-for="category-input" invalid-feedback="category is required">
                    <b-form-input id="category-input" v-model="category" required></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <b-modal
            v-model="updateModalIsActive"
            size="lg"
            centered
            :title="`Update category ${updateItem.name}`"
            @hidden="resetCreateModal"
            @ok="updateCategory"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Category" label-for="category-input" invalid-feedback="category is required">
                    <b-form-input id="category-input" v-model="category" required></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import VueNotifications from 'vue-notifications'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import Loading from '@/components/loading.vue'
import TrueOrFalseIcon from '@/components/TrueOrFalseIcon.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    components: {
        DetailsAction,
        Loading,
        TrueOrFalseIcon,
        WitTable,
    },
    mounted() {
        this.reloadCustomQueriesTableInterval = setInterval(this.reloadCustomQueriesTable, 5000)
    },
    created() {
        const {queryCreated, queryUpdated} = this.$route.query

        if (queryCreated) {
            this.$router.replace({query: {}})
            this.queryCreated()
        }

        if (queryUpdated) {
            this.$router.replace({query: {}})
            this.queryUpdated()
        }
    },
    // updatedAt, all or dedicated, remarketing
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
    },
    notifications: {
        queryCreated: {
            type: VueNotifications.types.success,
            title: 'Category has been successfully created.',
        },
        queryUpdated: {
            type: VueNotifications.types.success,
            title: 'Category has been successfully updated.',
        },
    },
    destroyed() {
        clearInterval(this.reloadCustomQueriesTableInterval)
    },
    data() {
        return {
            showDataStudioReports: false,
            customQueryError: {},
            customQueriesApiUrl: `${process.env.VUE_APP_NODE_API_HOST}/customQuery/categories`,
            customQueriesColumns: ['name', 'lastModified', 'actions'],
            createModalIsActive: false,
            updateModalIsActive: false,
            category: null,
            updateItem: {},
        }
    },
    methods: {
        async requestFunction(params) {
            const response = await this.axios.get(this.customQueriesApiUrl).catch(
                function(e) {
                    this.dispatch('error', e)
                }.bind(this)
            )

            return {
                data: response.data.data,
                count: response.data.metadata.count,
            }
        },

        resetCreateModal() {
            this.category = null
        },
        async createCategory() {
            const category = await this.axios.post(this.customQueriesApiUrl, {name: this.category})

            this.reloadCustomQueriesTable()
        },

        openUpdateModal(item) {
            this.updateModalIsActive = true
            this.updateItem = item
            this.category = item.name
        },

        async updateCategory() {
            const category = await this.axios.post(`${this.customQueriesApiUrl}/${this.updateItem.id}`, {
                name: this.category,
            })

            this.reloadCustomQueriesTable()
        },
        reloadCustomQueriesTable() {
            if (!document.hidden && this.$refs.customQueriesTable)
                this.$refs.customQueriesTable.fetchData({silent: true})
        },

        parseLocalDate(_date) {
            const date = new Date(_date)
            return !isNaN(date.getTime())
                ? `${date.toLocaleDateString('pl-PL')} ${date.toLocaleTimeString('pl-PL')}`
                : null
        },
    },
}
</script>

<style <style lang="scss">
.close-create-report {
    font-weight: 600;
    position: absolute;
    right: 0.5%;
    top: 5%;
    cursor: pointer;
}
.query-dedicated-row {
    padding-left: 1.7rem;
}
.query-remarketing-row {
    padding-left: 2.2rem;
}
</style>
